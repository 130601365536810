<template>
  <div>
    <div class="header">
      <Header></Header>
    </div>
    <div class="banner">
      <Banner></Banner>
    </div>

    <div class="page-body">
      <BreadcrumbNavi></BreadcrumbNavi>
      <div class="wrap">
        <div class="about">
          <div class="about-img">
            <img src="../assets/images/about_img.png" alt="" srcset="" />
          </div>
          <div class="about-text">
            <h3>芝兰互联网医院是在银川注册成立的正规医疗机构</h3>
            <p>
              通过移动端小程序，为患者提供在线问诊、用药指导、报告解读、早癌精准筛查、多学科会诊、健康档案、健康科普等服务；
            </p>
            <p>
              同时，拥有HIPAA资质认证的呼叫中心，整合智能随访系统，为患者提供个性化、高质量随访。
              芝兰互联网医院以单病种诊疗为基础，以患者为中心，集结知名医师和药师，搭建“满足患者全周期健康管理需求”的慢病管理平台；
              同时致力于提供专业的工具帮助医生提高患者管理效率、减轻医生工作负担，最终实现对医院和患者的双向赋能。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";
import BreadcrumbNavi from "@/components/BreadcrumbNavi.vue";

export default {
  components: {
    Header,
    Banner,
    Footer,
    BreadcrumbNavi,
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
.about {
  text-align: center;
  padding-top: 40px;
  .about-img {
    text-align: center;
    margin-bottom: 40px;
    img {
      width: 556px;
      height: auto;
    }
  }
  .about-text {
    width: 650px;
    margin: 0 auto;
    h3 {
      font-size: 24px;
      color: #333;
      font-weight: normal;
    }
    p {
      font-size: 16px;
      line-height: 1.8;
      margin: 20px 0;
    }
  }
}
</style>

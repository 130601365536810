<template>
  <div class="banner">
    <div class="banner-text">
      <div class="text-1">满足您全周期健康管理需求</div>
      <div class="text-2">
        同时致力于提供专业的工具帮助医生提高患者管理效率、减轻医生工作负担
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style lang="scss" scoped>
.banner {
  height: 500px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../assets/images/banner.png);
  background-size: cover;
  position: relative;
}
.banner-text {
  position: absolute;
  right: 170px;
  top: 200px;
  text-align: center;
  color: #fff;
  .text-1 {
    font-size: 48px;
    font-weight: bold;
  }
  .text-2 {
    font-size: 13px;
  }
}
</style>

<template>
  <div class="page-footer">
    <p>
      <span>Copyright © 2021-2022 银川芝兰互联网医院有限公司</span>
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=64010402001111"
        target="_blank"
        >宁公网安备64010402001111号</a
      >
      <a href="https://beian.miit.gov.cn" target="_blank"
        >宁ICP备2022000093号</a
      >
    </p>
    <p>
      <span>联系电话：09518732574</span>
      <span>地址：宁夏银川市兴庆区贺兰山东路帕克中心B座 2003 2005</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.page-footer {
  text-align: center;
  color: #999;
  font-size: 12px;
  position: relative;
  margin-top: 50px;
  padding-bottom: 40px;
  a {
    margin-right: 10px;
    color: #999;
    text-decoration: none;
  }
  a:hover {
    color: #00c6ff;
  }
  span {
    margin-right: 10px;
  }
}
.page-footer::before {
  content: " ";
  display: block;
  width: 1200px;
  background-color: #eee;
  margin: 0 auto;
  height: 3px;
  margin-bottom: 40px;
}
</style>

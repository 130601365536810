<template>
  <div>
    <div class="wrap">
      <div class="breadcrumbNavi flex">
        <div class="title">{{ currentRoute.meta.title }}</div>
        <div class="local">网站首页 > {{ currentRoute.meta.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { toRaw } from "vue";

export default {
  name: "breadcrumbNavi",
  setup() {
    let router = toRaw(useRouter());
    const currentRoute = router.currentRoute.value;
    return {
      currentRoute,
    };
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbNavi {
  justify-content: space-between;
  padding: 20px 0;
  position: relative;
  align-items: flex-end;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  .local {
    font-size: 14px;
    color: #999;
  }
}
.breadcrumbNavi::after {
  content: " ";
  display: block;
  width: 1200px;
  background-color: #eee;
  margin: 0 auto;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
